import React, { useState } from 'react';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import * as Actions from '@/app/store/actions/';
import { deleteAttachment, getStudent } from '@/app/services/students/students';
import dayjs from 'dayjs';
import HomeRoomDialog from './HomeRoomDialog';
import AttachmentModal from './AttachmentModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { tr } from 'date-fns/locale';
import { isSafari } from '@/utils/utils';

function RoomAttachmentsView({ student, isLoading, setCurrentState, setStudent, refresh, setRefresh, isAdmin }) {
	const [isFileLoading, setIsFileLoading] = useState(false);
	const dispatch = useDispatch();
	const openAttachmentModal = () => {
		dispatch(
			Actions.openDialog({
				children: <AttachmentModal refresAttachments={getStudentForFile} id={student?.id} />,
			})
		);
	};
	const handleNext = () => {
		if (student.home_room) {
			setCurrentState('contacts');
		} else {
			dispatch(
				Actions.showMessage({
					message: 'Please select Homeroom',
					autoHideDuration: 3000,
					variant: 'error',
					anchorOrigin: {
						vertical: isSafari() ? 'bottom' : 'top',
						horizontal: 'center',
					},
				})
			);
		}
	};

	const months = {
		'01': 'Jan',
		'02': 'Feb',
		'03': 'Mar',
		'04': 'Apr',
		'05': 'May',
		'06': 'Jun',
		'07': 'Jul',
		'08': 'Aug',
		'09': 'Sep',
		10: 'Oct',
		11: 'Nov',
		12: 'Dec',
	};

	const handleRemoveAttachment = (id) => {
		setIsFileLoading(true);
		deleteAttachment(id)
			.then(() => {
				dispatch(
					Actions.showMessage({
						message: 'File removed successfully',
						variant: 'success',
					})
				);
				getStudentForFile();
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: err.response.data.message,
						variant: 'error',
					})
				);
				setIsFileLoading(false);
			});
	};

	const getStudentForFile = () => {
		getStudent(student?.id)
			.then((res) => {
				setStudent(res.data);
				setIsFileLoading(false);
			})
			.catch((err) => {
				setIsFileLoading(false);
			});
	};
	const handleHomeRoomDialog = (data) => {
		if (data.checkin_status === 'checkout') {
			dispatch(
				Actions.openDialog({
					children: <HomeRoomDialog row={data} refresh={refresh} setRefresh={setRefresh} />,
				})
			);
		} else {
			dispatch(
				Actions.showMessage({
					message: 'Student is checked in',
					autoHideDuration: 2000,
					variant: 'error',
					anchorOrigin: {
						vertical: isSafari() ? 'bottom' : 'top',
						horizontal: 'center',
					},
				})
			);
		}
	};

	const formatDate = (date) => {
		const d = date.split('-');
		return `${months[d[1]]} ${d[2]}, ${d[0]}`;
	};
	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-5 gap-64 mb-32 pb-32 mt-16">
				<div className="sm:col-span-2">
					<div
						className={`flex items-center flex-nowrap justify-between mx-auto mb-8 ${
							!isAdmin ? 'sm:mt-16 lg:mt-8 md:mt-8' : ''
						}`}
					>
						<span className="md:text-lg lg:text-xl self-end font-extrabold">Room</span>
						{isAdmin && (
							<div className="flex justify-between">
								<span className="mx-4">
									<CustomButton
										variant="secondary"
										height="46"
										width="100px"
										fontSize="15px"
										disabled={isLoading}
										onClick={() => handleHomeRoomDialog(student)}
									>
										<FontAwesomeIcon icon={faEdit} />
										<span> Edit </span>
									</CustomButton>
								</span>
							</div>
						)}
					</div>
					<div className="flex items-center flex-nowrap  mx-auto bg-white p-32">
						{isLoading ? (
							<div className="flex align-center justify-center flex-grow">
								<CircularProgress size={35} />
							</div>
						) : (
							<div className="room-detail">
								<h4>Homeroom</h4>
								<div className="md:text-lg lg:text-lg mt-2 font-bold capitalize">
									{student?.home_room?.name || 'No Homeroom set'}
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="w-full sm:col-span-3">
					<div className="flex items-center flex-nowrap justify-between mx-auto mb-8">
						<span className="md:text-lg lg:text-xl self-end font-extrabold">Official Documents</span>
						<span>
							<div>
								<CustomButton
									variant="secondary"
									height="46"
									width="100px"
									fontSize="15px"
									disabled={isLoading}
									onClick={openAttachmentModal}
								>
									<FontAwesomeIcon icon={faPlus} />
									<span className="addstd"> Add</span>
								</CustomButton>
							</div>
						</span>
					</div>
					<div
						className={`flex flex-col mx-auto bg-white w-full ${isFileLoading && 'justify-center'}`}
						style={{ minHeight: '110px', maxHeight: '300px', overflow: 'auto' }}
					>
						{isLoading || isFileLoading ? (
							<div className="flex align-center justify-center my-auto">
								<CircularProgress size={35} />
							</div>
						) : (
							<div className="overflow-auto">
								<table className="w-full">
									<thead className="bg-blue-100">
										<th className="size-head font-bold p-8 px-16 med-table-headers text-left">
											Category
										</th>
										<th className="size-head font-bold p-8 px-16 med-table-headers text-left">
											File Name
										</th>
										<th className="size-head font-bold p-8 px-16 med-table-headers text-left">
											Expiry Date
										</th>
										<th className="size-head font-bold p-8 px-16 med-table-headers text-left">
											Attachment
										</th>
									</thead>
									<tbody style={{ maxHeight: 300, overflow: 'auto' }}>
										{student?.attachments.length ? (
											student?.attachments?.map((attachment) => {
												return (
													<tr className="border-b" key={attachment.id}>
														<td className=" mx-8 px-16 py-8  truncate">
															{attachment?.category}
														</td>
														<td className=" mx-8 px-16 py-8 truncate">
															{attachment?.name}
														</td>
														<td className=" mx-8 px-16 py-8 truncate">
															{attachment?.expiry_date ? (
																formatDate(attachment.expiry_date)
															) : (
																<div className=" pick-center">-</div>
															)}
														</td>
														<td className="mx-8 px-16 py-8 relative mt-10">
															<div className="relative mx-auto" style={{ width: 30 }}>
																<a
																	href={attachment.file}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<img
																		style={{
																			width: '30px',
																			// marginTop: '-5px',
																		}}
																		src="assets/images/pdf_thumbnail.png"
																		className="cursor-pointer mx-auto"
																		alt="file"
																	/>
																</a>
																<div
																	className="remove-attachment"
																	onClick={() =>
																		handleRemoveAttachment(attachment.id)
																	}
																>
																	x
																</div>
															</div>
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan={4}>
													<div className="flex justify-center mt-20 font-bold text-lg col-span-4 text-size-head">
														No Attachment
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="w-full flex justify-between mt-20 mb-80 pb-80 lg:mb-0 lg:pb-0">
				<CustomButton
					className=""
					variant="primary"
					height="46"
					width="120px"
					fontSize="15px"
					onClick={() => setCurrentState('medication')}
				>
					<div className="flex items-center justify-center">
						<ArrowBackIos className="" style={{ fontSize: '1.6rem' }} />
						Previous
					</div>
				</CustomButton>
				<CustomButton
					className=""
					variant="primary"
					height="46"
					width="100px"
					fontSize="15px"
					onClick={handleNext}
					disabled={isLoading}
				>
					<div className="flex items-center justify-center ml-6">
						Next
						<ArrowForwardIos className="ml-6" style={{ fontSize: '1.6rem' }} />
					</div>
				</CustomButton>
			</div>
		</>
	);
}

export default RoomAttachmentsView;

import React from 'react';
import { generateAgeString } from '@/utils/utils';
import history from '@/@history';
import { CircularProgress, Avatar, IconButton } from '@material-ui/core';
import dayjs from 'dayjs';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { ArrowForwardIos } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';

function StudentView({ student, isLoading, setCurrentState, isAdmin, token }) {
	const dispatch = useDispatch();
	const goToEditStudent = () => {
		if (isAdmin) {
			history.push({
				pathname: `/students-editstudent/${student.id}`,
				state: { student, tab: 'personal' },
			});
		} else {
			history.push('/studentformedit', { student, tab: 'personal', token });
		}
	};
	const handleNext = () => {
		if (
			student?.first_name &&
			student?.last_name &&
			student?.date_of_birth &&
			student?.gender &&
			student?.address1 &&
			student?.country_id &&
			student?.state_id &&
			student?.city &&
			student?.zip_code &&
			student?.photo &&
			student?.photo !== `${import.meta.env.VITE_S3_BASE_URL}${import.meta.env.VITE_PERSONAL_AVATAR_URL}`
		) {
			setCurrentState('medication');
		} else if (
			student?.photo === `${import.meta.env.VITE_S3_BASE_URL}${import.meta.env.VITE_PERSONAL_AVATAR_URL}` ||
			!student?.photo
		) {
			dispatch(
				Actions.showMessage({
					message: 'Please upload the photo',
					autoHideDuration: 3000,
					variant: 'error',
				})
			);
		} else {
			dispatch(
				Actions.showMessage({
					message: 'Please fill the personal information',
					autoHideDuration: 3000,
					variant: 'error',
				})
			);
		}
	};
	return (
		<>
			<div className="flex items-center justify-between">
				<div>
					{isAdmin && (
						<span className="sm:mr-12 icon-color">
							<IconButton
								onClick={() => {
									history.push('/students');
								}}
							>
								<img
									src="assets/images/arrow-long.png"
									alt="filter"
									width="24px"
									className="fiterimg"
								/>
							</IconButton>
						</span>
					)}
					<span className="md:text-lg lg:text-xl self-end font-extrabold">Personal Information</span>
				</div>
				<div className="flex justify-between">
					<span className="mx-4">
						<CustomButton
							variant="secondary"
							height="46"
							width="100px"
							fontSize="15px"
							onClick={goToEditStudent}
							disabled={isLoading}
						>
							<FontAwesomeIcon icon={faEdit} />
							<span> Edit </span>
						</CustomButton>
					</span>
				</div>
			</div>
			<div className="bg-white rounded-lg p-32 py-44 mx-auto mt-20 sm:pr-32">
				<div className="flex flex-wrap sm:flex-nowrap justify-center">
					<div className="mx-64 flex-shrink-0">
						<Avatar style={{ height: '140px', width: '140px' }} src={student?.photo} />
					</div>
					{isLoading ? (
						<div className="flex items-center justify-center flex-grow">
							<CircularProgress size={35} />
						</div>
					) : (
						<div
							className="grid grid-cols-2 sm:grid-cols-3 md:flex-grow mx-auto mt-32 sm:mt-0"
							style={{ gap: '30px' }}
						>
							<div className="">
								<div style={{ fontSize: 14 }}>Name</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word capitalize">
									{student?.first_name} {student?.last_name}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>Birthday</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word">
									{student?.date_of_birth
										? dayjs(student?.date_of_birth).format('MMM DD, YYYY')
										: '-'}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>Age</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word">
									{generateAgeString(student?.date_of_birth)}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>Gender</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word capitalize">
									{student?.gender || '-'}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>Address</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word">
									{student?.address1 ||
										`${student?.first_name} ${student?.last_name} has no address on record`}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>Country</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word capitalize">
									{student?.country?.name || '-'}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>State</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word capitalize">
									{student?.state?.name || '-'}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>City</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word capitalize">
									{student?.city?.name || '-'}
								</div>
							</div>
							<div className="">
								<div style={{ fontSize: 14 }}>Zipcode</div>
								<div style={{ fontSize: 16 }} className="font-bold turncate break-word capitalize">
									{student?.zip_code || '-'}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="w-full flex justify-end mt-20 mb-80 pb-80 lg:mb-64 lg:pb-64">
				<CustomButton
					className=""
					variant="primary"
					height="46"
					width="100px"
					fontSize="15px"
					disabled={isLoading}
					onClick={handleNext}
				>
					<div className="flex items-center justify-center ml-6">
						Next
						<ArrowForwardIos className="ml-6" style={{ fontSize: '1.6rem' }} />
					</div>
				</CustomButton>
			</div>
		</>
	);
}

export default StudentView;

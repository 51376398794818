/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { Avatar, CircularProgress } from '@material-ui/core';
import '../live/index.css';
import Hls from 'hls.js';
import CustomControls from '../live/CustomControls';

function Streaming({ room, active }) {
	const [url, setUrl] = useState('');
	const playerRef = useRef(null);
	const boxRef = useRef(null);
	const hls = new Hls({
		maxMaxBufferLength: 10,
		liveSyncDuration: 3,
		liveMaxLatencyDuration: 5,
	});
	useEffect(() => {
		if (active) {
			if (Hls.isSupported()) {
				hls.loadSource(active);
				hls.attachMedia(playerRef.current);
				setUrl(active);
			}
		} else {
			setUrl('');
		}
		return () => {
			hls.destroy();
		};
	}, [active]);

	return (
		<div className="flex justify-center items-center">
			<div ref={boxRef} className="w-full history-video-player-box rounded-lg">
				<video
					id="myVideo"
					preload="none"
					className="rounded-lg history-video-player"
					autoPlay
					ref={playerRef}
					// controls={url || false}
					// controlsList="nodownload"
					muted
				/>
				{url && <CustomControls room={room?.id} playerRef={playerRef} boxRef={boxRef} />}
				<div className="video-player-title flex items-center col-gap-8">
					<Avatar src={room?.thumb} />
					<div>{room?.name}</div>
				</div>
				{!active ? <div className="stream-not-running-text-history">No video available</div> : ''}
			</div>
		</div>
	);
}

export default Streaming;

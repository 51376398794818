/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Hls from 'hls.js';
import './index.css';
import { Avatar } from '@material-ui/core';
import { getRooms } from '@/app/services/liveStreaming/liveStreaming';
import config from './hlsconfig';
import CustomControls from './CustomControls';

function Streaming({ room, setSearch }) {
	const [videoBannerText, setVideoBannerText] = useState('Loading');
	const playerRef = useRef(null);
	const boxRef = useRef(null);

	let currentPlaybackPosition = 0;
	let hls = null;
	const timeouts = [];

	const apiReCall = async () => {
		try {
			const res = await getRooms(room.name, '', 1);
			return res.data.data[0];
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		let roomLoading = '';
		const clearTimeouts = () => {
			timeouts.forEach((timeoutId) => {
				clearTimeout(timeoutId);
			});
		};
		const initializeHls = () => {
			if (hls && room?.stream_url?.session_url) {
				hls.loadSource(room?.stream_url?.session_url);

				hls.attachMedia(playerRef.current);

				hls.on(Hls.Events.MEDIA_ATTACHED, () => {
					hls.on(Hls.Events.ERROR, handleHlsError);
					hls.on(Hls.Events.MANIFEST_PARSED, () => {
						hls.startLoad();
					});
				});

				hls.on(Hls.Events.MANIFEST_LOADED, () => {
					// HLS manifest has been loaded
					// console.log('can play', room.name);
					playerRef.current.play().catch((error) => {});
				});
			}
		};
		const handleFragmentLoadError = async () => {
			// Skip the problematic fragment and continue streaming
			const currentLevel = hls.currentLevel;
			const currentFragment = hls.mediaBuffer ? hls.mediaBuffer : hls.media;

			if (currentLevel !== null && currentFragment !== null) {
				hls.nextLoadPosition = hls.config.startPosition;

				if (roomLoading !== room.name) {
					roomLoading = room.name;

					const roomData = await apiReCall();
					// Retry loading the next fragment after a delay
					setTimeout(() => {
						clearTimeouts();

						if (hls && roomData?.stream_url?.session_url) {
							hls.loadSource(roomData?.stream_url?.session_url);
							hls.attachMedia(playerRef.current);
							hls.on(Hls.Events.MEDIA_ATTACHED, () => {
								// Restore the current playback position
								// console.log('room live media attachMedia', room.name);

								playerRef.current.currentTime = currentPlaybackPosition;
								hls.startLoad();
							});
						}

						roomLoading = '';
						retryNetworkCount = 0;
					}, config.fragLoadingRetryDelay);
				}
			} else {
				// If currentLevel or currentFragment is null, there is no more content to load
				// console.log('Unable to resolve fragment loss. Stream may be interrupted.');
			}
		};
		let retryNetworkCount = 0;
		const handleHlsError = (event, data) => {
			if (data.fatal) {
				switch (data.type) {
					case Hls.ErrorTypes.NETWORK_ERROR:
						retryNetworkCount++;
						handleFragmentLoadError();

						break;
					case Hls.ErrorTypes.MEDIA_ERROR:
						handleFragmentLoadError();

						break;

					default:
						handleFragmentLoadError();
						break;
				}
			}
		};

		const handleBuffering = () => {
			setVideoBannerText('Connecting');

			timeouts.push(
				setTimeout(() => {
					clearTimeouts();
					if (hls && room?.stream_url?.session_url) {
						hls.attachMedia(playerRef.current);
						hls.loadSource(room?.stream_url?.session_url);

						hls.on(Hls.Events.MEDIA_ATTACHED, () => {
							hls.on(Hls.Events.MANIFEST_PARSED, () => {
								hls.startLoad();
							});
						});
					}
				}, 10000)
			);
		};

		if (room?.stream_url?.session_url) {
			if (Hls.isSupported()) {
				hls = new Hls(config);

				initializeHls();

				playerRef.current.addEventListener('waiting', handleBuffering);
				playerRef.current.addEventListener('playing', () => {
					clearTimeouts();
					setVideoBannerText('Live');
				});

				playerRef.current.addEventListener('pause', () => {});
			}
		}

		return () => {
			clearTimeouts();

			if (hls) {
				hls.destroy();
				hls = null;
			}
			if (playerRef.current) {
				playerRef.current.removeEventListener('waiting', handleBuffering);
				playerRef.current.removeEventListener('playing', () => {
					clearTimeouts();
					setVideoBannerText('Live');
				});
				playerRef.current.removeEventListener('pause', () => {});
			}
		};
	}, [room?.stream_url?.session_url]);

	const isLive = () => videoBannerText === 'Live';

	return (
		<div className="flex justify-center items-center">
			<div ref={boxRef} id={`video-container-${room?.id}`} className="w-full video-player-box rounded-lg">
				<video
					preload="true"
					// controls
					id={room?.id}
					ref={playerRef}
					autoPlay
					muted
					className="rounded-lg video-player object-fill"
					controlsList="nodownload noplaybackrate"
				></video>
				<div className="video-player-title flex items-center col-gap-8">
					<Avatar src={room?.thumb} />
					<div>{room?.name}</div>
				</div>
				{room?.stream_url?.overlay_image && (
					<img
						id="overlayImage"
						className="img-overlay-blur"
						src={room?.stream_url?.overlay_image}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
						}}
						height={boxRef.current?.clientHeight}
						width={boxRef.current?.clientWidth}
					/>
				)}
				{room?.stream_url?.session_url ? (
					<>
						<div className={`btn_live ${isLive() ? '' : 'not_btn_live'} z-20`}>
							{videoBannerText || 'Live'}
							<span className={`${isLive() ? 'live-icon' : 'not-live-icon'}`} />
						</div>
						<CustomControls room={room} playerRef={playerRef} boxRef={boxRef} live={true} />
					</>
				) : (
					''
				)}
				{!room?.stream_url?.session_url ? (
					<div className="stream-not-running-text">Live Streaming Is Not Available</div>
				) : (
					''
				)}
			</div>
		</div>
	);
}

export default Streaming;

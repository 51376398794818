import React from 'react';
import authRoles from '../../../auth/authRoles';
import StudentInformation from './StudentInformation';
import EditStudent from '../../../fuse-layouts/shared-components/student-form/EditStudent';
import AddEditContact from '../../../fuse-layouts/shared-components/student-form/AddEditContact';
import EditMedications from '../../../fuse-layouts/shared-components/student-form/EditMedications';
import NewLayout from './NewLayout';

const StudentInformationConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				footer: {
					display: false,
				},
			},
		},
	},
	routes: [
		// {
		// 	path: '/student',
		// 	component: React.lazy(() => import('./StudentInformation'))
		// },
		{
			path: '/students-student/:id',
			// component: NewLayout,
			component: React.lazy(() => import('./NewLayout')),
		},
		{
			path: '/students-editstudent/:id',
			// component: EditStudent,
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/student-form/EditStudent')),
		},
		{
			path: '/students-contact',
			// component: AddEditContact,
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/student-form/AddEditContact')),
		},
		{
			path: '/students-editmedications',
			// component: EditMedications,
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/student-form/EditMedications')),
		},
	],
	auth: authRoles.subadmin,
};

export default StudentInformationConfig;

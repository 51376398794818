import FusePageSimple from '@/@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState, useEffect } from 'react';
import FuseAnimate from '@/@fuse/core/FuseAnimate';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import CustomDatePicker from '@/app/customComponents/CustomDatePicker/CustomDatePicker';
import clsx from 'clsx';
import { MenuItem, Select, CircularProgress, FormControl } from '@material-ui/core';
import { getRooms } from '@/app/services/rooms/rooms';
import { getHistoryVideo, saveRoomClips } from '@/app/services/liveStreaming/liveStreaming';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';
import './historyCamera.css';
import dayjs from 'dayjs';
import HistoryStreaming from './HistoryStreaming';

const useStyles = makeStyles({
	layoutRoot: {},
	sidebar: {
		width: 320,
	},
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select.MuiSelect-select': {
			textAlign: 'left',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
	},
	dateStyle: {
		'& .MuiInput-root::after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline::before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover::before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			cursor: 'pointer',
		},
		'& .MuiInput-root': {
			cursor: 'pointer',
		},
	},
	divider: {
		backgroundColor: 'grey',
		width: '100%',
		height: 1,
	},
});

function Historycamera() {
	const [rooms, setRooms] = useState([]);
	const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
	const [isLoading, setIsLoading] = useState(true);
	const [roomPage, setRoomPage] = useState(1);
	const [roomData, setRoomData] = useState('');
	const [historyVideoData] = useState([
		{
			id: 0,
			start_time: '06:00:00',
			end_time: '07:00:00',
			timings: [
				{ id: 0, start_time: '06:00:00', end_time: '06:20:00' },
				{ id: 1, start_time: '06:20:00', end_time: '06:40:00' },
				{ id: 2, start_time: '06:40:00', end_time: '07:00:00' },
			],
		},
		{
			id: 1,
			start_time: '07:00:00',
			end_time: '08:00:00',
			timings: [
				{ id: 0, start_time: '07:00:00', end_time: '07:20:00' },
				{ id: 1, start_time: '07:20:00', end_time: '07:40:00' },
				{ id: 2, start_time: '07:40:00', end_time: '08:00:00' },
			],
		},
		{
			id: 2,
			start_time: '08:00:00',
			end_time: '09:00:00',
			timings: [
				{ id: 0, start_time: '08:00:00', end_time: '08:20:00' },
				{ id: 1, start_time: '08:20:00', end_time: '08:40:00' },
				{ id: 2, start_time: '08:40:00', end_time: '09:00:00' },
			],
		},
		{
			id: 3,
			start_time: '09:00:00',
			end_time: '10:00:00',
			timings: [
				{ id: 0, start_time: '09:00:00', end_time: '09:20:00' },
				{ id: 1, start_time: '09:20:00', end_time: '09:40:00' },
				{ id: 2, start_time: '09:40:00', end_time: '10:00:00' },
			],
		},
		{
			id: 4,
			start_time: '10:00:00',
			end_time: '11:00:00',
			timings: [
				{ id: 0, start_time: '10:00:00', end_time: '10:20:00' },
				{ id: 1, start_time: '10:20:00', end_time: '10:40:00' },
				{ id: 2, start_time: '10:40:00', end_time: '11:00:00' },
			],
		},
		{
			id: 5,
			start_time: '11:00:00',
			end_time: '12:00:00',
			timings: [
				{ id: 0, start_time: '11:00:00', end_time: '11:20:00' },
				{ id: 1, start_time: '11:20:00', end_time: '11:40:00' },
				{ id: 2, start_time: '11:40:00', end_time: '12:00:00' },
			],
		},
		{
			id: 6,
			start_time: '12:00:00',
			end_time: '13:00:00',
			timings: [
				{ id: 0, start_time: '12:00:00', end_time: '12:20:00' },
				{ id: 1, start_time: '12:20:00', end_time: '12:40:00' },
				{ id: 2, start_time: '12:40:00', end_time: '13:00:00' },
			],
		},
		{
			id: 7,
			start_time: '13:00:00',
			end_time: '14:00:00',
			timings: [
				{ id: 0, start_time: '13:00:00', end_time: '13:20:00' },
				{ id: 1, start_time: '13:20:00', end_time: '13:40:00' },
				{ id: 2, start_time: '13:40:00', end_time: '14:00:00' },
			],
		},
		{
			id: 8,
			start_time: '14:00:00',
			end_time: '15:00:00',
			timings: [
				{ id: 0, start_time: '14:00:00', end_time: '14:20:00' },
				{ id: 1, start_time: '14:20:00', end_time: '14:40:00' },
				{ id: 2, start_time: '14:40:00', end_time: '15:00:00' },
			],
		},
		{
			id: 9,
			start_time: '15:00:00',
			end_time: '16:00:00',
			timings: [
				{ id: 0, start_time: '15:00:00', end_time: '15:20:00' },
				{ id: 1, start_time: '15:20:00', end_time: '15:40:00' },
				{ id: 2, start_time: '15:40:00', end_time: '16:00:00' },
			],
		},
		{
			id: 10,
			start_time: '16:00:00',
			end_time: '17:00:00',
			timings: [
				{ id: 0, start_time: '16:00:00', end_time: '16:20:00' },
				{ id: 1, start_time: '16:20:00', end_time: '16:40:00' },
				{ id: 2, start_time: '16:40:00', end_time: '17:00:00' },
			],
		},
		{
			id: 11,
			start_time: '17:00:00',
			end_time: '18:00:00',
			timings: [
				{ id: 0, start_time: '17:00:00', end_time: '17:20:00' },
				{ id: 1, start_time: '17:20:00', end_time: '17:40:00' },
				{ id: 2, start_time: '17:40:00', end_time: '18:00:00' },
			],
		},
		{
			id: 12,
			start_time: '18:00:00',
			end_time: '19:00:00',
			timings: [
				{ id: 0, start_time: '18:00:00', end_time: '18:20:00' },
				{ id: 1, start_time: '18:20:00', end_time: '18:40:00' },
				{ id: 2, start_time: '18:40:00', end_time: '19:00:00' },
			],
		},
	]);
	const [activeId, setActiveId] = useState(null);
	const [streamUrl, setstreamUrl] = useState('');
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [clip, setClip] = useState(null);

	const dispatch = useDispatch();
	const classes = useStyles();
	const pageLayout = useRef(null);

	useEffect(() => {
		setIsLoading(true);
		getRooms('', roomPage)
			.then((res) => {
				setRooms([...rooms, ...res?.data?.data]);
				if (roomPage == 1) {
					setRoomData(res?.data?.data[0]);
				}
				if (res.data.current_page < res.data.last_page) {
					setRoomPage(roomPage + 1);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to get rooms.',
						autoHideDuration: 1500,
						variant: 'error',
					})
				);
				setIsLoading(false);
			});
	}, [roomPage, dispatch]);

	const videoClick = (item) => {
		setActiveId(item);
		setDropdownOpen(!dropdownOpen);
		setClip(null);
	};

	const clipSelect = (item) => {
		setClip(item);
		if (roomData) {
			getHistoryVideo(`${date} ${item?.start_time}`, `${date} ${item?.end_time}`, roomData?.id)
				.then((res) => {
					console.log(res.data.data.session_url);
					setstreamUrl(res?.data?.data?.session_url);
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: 'Something went wrong please try again',
							autoHideDuration: 1500,
							variant: 'error',
						})
					);
				});
		}
	};

	const handleChange = (e, v) => {
		setRoomData(v.props.value);
		setActiveId(null);
		setstreamUrl(null);
		setClip(null);
		setDropdownOpen(false);
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};
	return (
		<div style={{ zIndex: -1 }}>
			<FusePageSimple
				classes={{
					root: classes.layoutRoot,
					sidebar: classes.sidebar,
				}}
				content={
					<div className="pl-72 pr-72 pt-20 pb-64 main-content">
						<br />
						<FuseAnimate animation="transition.slideLeftIn" duration={600}>
							<div>
								<div className="w-full">
									<div className="flex items-end">
										<h4 className="font-extrabold child-hd self-end">
											{activeId ? `${roomData?.name} -` : ''}
										</h4>
										<span className="child-hd m-1 ml-8">
											{activeId
												? `Recorded ${dayjs(`${date} ${activeId?.start_time}`).format(
														'MMMM D, YYYY h:mm A'
												  )}`
												: ''}
										</span>
									</div>
									<div className="mt-20">
										<HistoryStreaming room={roomData} active={streamUrl} />
									</div>
								</div>
							</div>
						</FuseAnimate>
					</div>
				}
				rightSidebarContent={
					<div className="home-sidebar-history">
						<div className=" mb-16">
							<h2 className="font-bold mb-10" style={{ fontSize: '18px' }}>
								Room
							</h2>

							<div className="room-select">
								<FormControl
									style={{ width: '100%', position: 'relative', height: '50px', padding: '10px' }}
								>
									<Select
										className={classes.select}
										inputProps={{
											classes: {
												root: classes.root,
												icon: classes.icon,
											},
										}}
										name="isRoom"
										value={roomData}
										id="isRoom"
										onChange={handleChange}
										MenuProps={MenuProps}
									>
										{rooms.length ? (
											rooms.map((room) => {
												return (
													<MenuItem key={room.id} value={room}>
														<div className="menu-item" id={room.id}>
															{room.name}
														</div>
													</MenuItem>
												);
											})
										) : (
											<MenuItem>Loading...</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>
						</div>

						<div className="date-div">
							<h2 className="font-bold mb-10" style={{ fontSize: '18px' }}>
								Date
							</h2>

							<div className="date-select">
								<CustomDatePicker
									dateStyle={classes.dateStyle}
									id="date-from"
									paddingLeft="11px"
									paddingRight="11px"
									value={date}
									setValue={(d) => {
										setDate(d?.format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'));
										setstreamUrl(null);
										setClip(null);
										setActiveId(null);
										setDropdownOpen(false);
									}}
									disableFuture
								/>
							</div>
						</div>

						<div className={clsx(classes.divider)} />

						{isLoading ? (
							<div className="flex justify-center mt-16">
								<CircularProgress size={35} />
							</div>
						) : (
							<>
								<div className="mt-12 video-history">
									{historyVideoData.map((item, index) => {
										return (
											<>
												<div
													className="small-img-row"
													key={item?.id}
													onClick={() => videoClick(item)}
													style={{
														cursor: 'pointer',
													}}
												>
													<div className="small-img">
														<img
															className="small-img"
															src="assets/images/Group 86539.png"
														/>
													</div>
													<p> {dayjs(`${date}${item?.start_time}`).format('h:mm A')}</p>
													<p>-</p>
													<p>{dayjs(`${date}${item?.end_time}`).format('h:mm A')}</p>
													<div>
														<img src="assets/images/Dropdown.png" />
													</div>
												</div>
												{dropdownOpen && (
													<>
														{item?.id === activeId?.id && (
															<div className="dropdown">
																<ul>
																	{item?.timings?.map((time, index) => {
																		return (
																			<li
																				key={index}
																				onClick={() => clipSelect(time)}
																			>
																				<div className="display-content">
																					<div className="display-img">
																						<img
																							className="display-img"
																							src="assets/images/Group 86539.png"
																						/>
																					</div>
																					<div className="display-text">
																						<p>
																							{' '}
																							{dayjs(
																								`${date}${time?.start_time}`
																							).format('h:mm A')}
																						</p>
																						{'-'}
																						<p>
																							{dayjs(
																								`${date}${time?.end_time}`
																							).format('h:mm A')}
																						</p>
																					</div>
																					<div
																						className="tick-wrapper-custom school-ticket mt-6"
																						style={{
																							background:
																								time.id === clip?.id
																									? '#4DA0EE'
																									: 'white',
																						}}
																					>
																						<i className="fas fa-check" />
																					</div>
																				</div>
																				{item?.timings?.length - 1 !==
																					index && (
																					<div className="line-br" />
																				)}
																			</li>
																		);
																	})}
																</ul>
															</div>
														)}
													</>
												)}
											</>
										);
									})}
								</div>
							</>
						)}
					</div>
				}
				innerScroll
				ref={pageLayout}
			/>
		</div>
	);
}

export default Historycamera;

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

function CustomPhoneInput({ value, onChange, label, errTxt, required, style, divProps, disabled }) {
	return (
		<div {...divProps}>
			<label className={`text-xs text-${errTxt ? 'red' : 'gray'}-600`}>
				{label}
				{required && <span className="ml-2">*</span>}
			</label>
			<PhoneInput
				disabled={disabled}
				country={'us'}
				specialLabel=""
				value={value}
				onChange={onChange}
				inputProps={{
					className: `form-control ${(errTxt && 'is-invalid') || 'is-valid'}`,
					style,
				}}
			/>
			{errTxt && <p className="error text-xs text-red mt-6">{errTxt}</p>}
		</div>
	);
}

export default CustomPhoneInput;

/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import {
	TextField,
	Avatar,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	FormHelperText,
	CircularProgress,
	IconButton,
} from '@material-ui/core/';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import history from '@/@history';
import { getRelations, preFetchData } from '@/app/services/students/students';
import { uploadFile } from '@/app/services/imageUpload/imageUpload';
import { createContact, updateContact } from '@/app/services/contacts/contacts';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';
import { getImageUrl, isImageFile, isInValidName } from '@/utils/utils';
import { debounce } from 'lodash';
import FuseScrollbars from '@/@fuse/core/FuseScrollbars';
import { makeStyles } from '@material-ui/core/styles';
import { isPossiblePhoneNumber } from 'libphonenumber-js/min';
import CustomPhoneInput from '@/app/customComponents/CustomPhoneInput/CustomPhoneInput';

const useStyles = makeStyles((theme) => ({
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
}));

function AddEditContact() {
	const classes = useStyles();
	const token = history.location?.state?.token;
	const dispatch = useDispatch();
	useEffect(() => {
		if (!history.location.state?.row) {
			history.goBack();
		}
	}, [history.location.state?.row]);
	const { contact, row, isEdit } = history.location.state;
	const [relations, setRelations] = useState([]);
	const [form, setForm] = useState(
		contact?.id
			? { ...contact, student_id: row.id, relation_id: contact.relation_id || 1 }
			: { student_id: row?.id, first_name: '', last_name: '', phone: '', email: '', is_primary_account: 0 }
	);
	const [errTxts, setErrTxts] = useState({});
	const [isAdding, setIsAdding] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState();
	const inputRef = useRef(null);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(contact?.photo || null);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile, contact?.photo]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(contact?.photo || null);
			return;
		}
		const file = e.target.files[0];
		if (!isImageFile(file)) {
			dispatch(Actions.showMessage({ message: 'Please select an image file', variant: 'error' }));
		} else {
			setSelectedFile(e.target.files[0]);
		}
	};
	useEffect(() => {
		getRelations().then((res) => setRelations(res.data));
	}, []);

	useEffect(() => {
		if (!form?.email || isEdit) return;
		const fetchData = async () => {
			// Make your API call here
			// if email doesnt exist or invalid email is entered, dont call api
			if (!form?.parent_email) return;
			if (!/^\S+@\S+\.\S+$/.test(form.parent_email)) return;
			const res = await preFetchData(form?.email);

			if (res?.data) {
				setForm((prevForm) => ({
					...prevForm,
					first_name: res.data.first_name,
					last_name: res.data.last_name,
					phone: res.data.phone,
				}));
				setPreview(res?.data?.photo);
			}
		};

		// Create a debounced function with a delay of 1000ms
		const debouncedFetchData = debounce(fetchData, 1000);

		// Call the debounced function every time form.email changes
		debouncedFetchData();

		// Cleanup function to cancel the debounced function
		return () => {
			debouncedFetchData.cancel();
		};
	}, [form?.email]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setErrTxts({ ...errTxts, [name]: '' });
		setForm({ ...form, [name]: value });
	};

	const handleSubmit = () => {
		setErrTxts({});

		if (!form.first_name) {
			setErrTxts({ ...errTxts, first_name: 'This field is required' });
			return;
		}
		if (form.first_name && isInValidName(form.first_name)) {
			setErrTxts({ ...errTxts, first_name: 'Please enter a valid name.' });
			return;
		}
		form.first_name = form.first_name.trim();
		if (!form.last_name) {
			setErrTxts({ ...errTxts, last_name: 'This field is required' });
			return;
		}
		if (form.last_name && isInValidName(form.last_name)) {
			setErrTxts({ ...errTxts, last_name: 'Please enter a valid name.' });
			return;
		}
		form.last_name = form.last_name.trim();
		if (!form.relation_id) {
			setErrTxts({ ...errTxts, relation_id: 'This field is required' });
			return;
		}
		if (!form.relation_with_child) {
			setErrTxts({ ...errTxts, relation_with_child: 'This field is required' });
			return;
		}
		if (!form.email) {
			setErrTxts({ ...errTxts, email: 'This field is required' });
			return;
		}
		if (!/^\S+@\S+\.\S+$/.test(form.email)) {
			setErrTxts({ ...errTxts, email: 'Please enter valid email' });
			return;
		}
		if (!form.phone) {
			setErrTxts({ ...errTxts, phone: 'This field is required' });
			return;
		}
		if (form.phone) {
			if (isPossiblePhoneNumber(form.phone) === false) {
				setErrTxts({ ...errTxts, phone: 'Please enter valid phone number' });
				return;
			}
		}
		if (!(form.can_pickup === 1 || form.can_pickup === 0)) {
			setErrTxts({ ...errTxts, can_pickup: 'This field is required' });
			return;
		}
		if (isEdit && contact.role === 'parent') {
			form.is_parent = 1;
		} else {
			form.is_parent = 0;
		}
		console.log(history.location.state, 'history.location.state');
		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			setIsAdding(true);
			uploadFile(selectedFile, filename)
				.then((response) => {
					form.photo = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
					form.thumb = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
					if (isEdit) {
						updateContact(contact?.id, history.location.state?.row.id, {
							...form,
							relation_id: form.relation_id === 3 ? 1 : form.relation_id,
						})
							.then((r) => {
								dispatch(
									Actions.showMessage({
										message: r.data.message,
										autoHideDuration: 1500,
										variant: 'success',
									})
								);
								if (token) {
									history.push(`studentform?token=${token}`, { tab: 'contacts' });
								} else {
									history.push(`students-student/${row.id}`, { tab: 'contacts' });
								}
							})
							.catch((err) => {
								if (err.response?.data?.errors) {
									setErrTxts(err.response.data.errors);
								} else {
									dispatch(
										Actions.showMessage({
											message: 'Failed to update contact.',
											autoHideDuration: 1500,
											variant: 'error',
										})
									);
								}
							})
							.finally(() => setIsAdding(false));
					} else {
						setIsAdding(true);
						createContact(form)
							.then((resp) => {
								dispatch(
									Actions.showMessage({
										message: resp.data.message,
										autoHideDuration: 1500,
										variant: 'success',
									})
								);
								if (token) {
									history.push(`studentform?token=${token}`, { tab: 'contacts' });
								} else {
									history.push(`students-student/${row.id}`, { tab: 'contacts' });
								}
							})
							.catch((err) => {
								if (err.response?.data?.errors) {
									setErrTxts(err.response.data.errors);
								} else if (err.response?.data?.message) {
									dispatch(
										Actions.showMessage({
											message: err.response.data.message,
											autoHideDuration: 1500,
											variant: 'error',
										})
									);
								} else {
									dispatch(
										Actions.showMessage({
											message: 'Failed to add contact.',
											autoHideDuration: 1500,
											variant: 'error',
										})
									);
								}
							})
							.finally(() => setIsAdding(false));
					}
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: 'Failed to upload image.',
							autoHideDuration: 1500,
							variant: 'error',
						})
					);
					setIsAdding(false);
				});
		} else {
			if (isEdit) {
				setIsAdding(true);
				updateContact(contact?.id, history.location.state?.row?.id, {
					...form,
					relation_id: form.relation_id === 3 ? 1 : form.relation_id,
				})
					.then((r) => {
						dispatch(
							Actions.showMessage({
								message: r.data.message,
								autoHideDuration: 1500,
								variant: 'success',
							})
						);
						if (token) {
							history.push(`studentform?token=${token}`, { tab: 'contacts' });
						} else {
							history.push(`students-student/${row.id}`, { tab: 'contacts' });
						}
					})
					.catch((err) => {
						if (err.response?.data?.errors) {
							setErrTxts(err.response.data.errors);
						} else {
							dispatch(
								Actions.showMessage({
									message: 'Failed to update contact.',
									autoHideDuration: 1500,
									variant: 'error',
								})
							);
						}
					})
					.finally(() => setIsAdding(false));
			} else {
				setIsAdding(true);
				createContact(form)
					.then((resp) => {
						dispatch(
							Actions.showMessage({
								message: resp.data.message,
								autoHideDuration: 1500,
								variant: 'success',
							})
						);
						if (token) {
							history.push(`studentform?token=${token}`, { tab: 'contacts' });
						} else {
							history.push(`students-student/${row.id}`, { tab: 'contacts' });
						}
					})
					.catch((err) => {
						if (err.response?.data?.errors) {
							setErrTxts(err.response.data.errors);
						} else if (err.response?.data?.message) {
							dispatch(
								Actions.showMessage({
									message: err.response.data.message,
									autoHideDuration: 1500,
									variant: 'error',
								})
							);
						} else {
							dispatch(
								Actions.showMessage({
									message: 'Failed to add contact.',
									autoHideDuration: 1500,
									variant: 'error',
								})
							);
						}
					})
					.finally(() => setIsAdding(false));
			}
		}
	};

	const goBack = () => {
		if (token) {
			history.push(`studentform?token=${token}`, { tab: 'contacts' });
		} else {
			history.goBack();
		}
	};

	return (
		<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
			<div className="w-9/12 mx-auto my-64 pb-80">
				<div className="flex items-center flex-nowrap justify-between mx-auto" style={{ marginTop: '11px' }}>
					<span className="personal-hd info-hd stext-2xl self-end font-extrabold ">
						<h1 className="md:text-lg lg:text-xl self-end font-extrabold">
							{' '}
							<span className="mr-12 icon-color">
								<IconButton onClick={goBack}>
									<img
										src="assets/images/arrow-long.png"
										alt="filter"
										width="24px"
										className="fiterimg"
									/>
								</IconButton>
							</span>{' '}
							{isEdit ? 'Edit' : 'Add'} Contact
						</h1>
					</span>
				</div>

				<div className="bg-white rounded p-32">
					<div className="flex justify-center flex-wrap md:flex-nowrap gap-12 md:gap-64">
						<div className="mx-40 image-top">
							<div
								className="relative pic-upload-overlay cursor-pointer"
								onClick={() => inputRef.current.click()}
							>
								<Avatar style={{ height: '140px', width: '140px' }} className="mx-auto" src={preview} />
								<div className="pp-input-overlay contactupload">
									<i className="fa fa-2x fa-camera" />
								</div>
								<input
									onChange={onSelectFile}
									type="file"
									name="image"
									id="image"
									className="hidden"
									accept="image/*"
									ref={inputRef}
								/>
							</div>
						</div>

						<div
							className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-32 flex-grow"
							style={{ rowGap: '1.2rem', columnGap: '3.2rem' }}
						>
							<TextField
								name="first_name"
								onChange={handleChange}
								value={form.first_name}
								label="First Name"
								className="w-2/3 my-12 width-first-name"
								error={!!errTxts.first_name}
								helperText={errTxts.first_name}
								required
							/>
							<TextField
								error={!!errTxts.last_name}
								helperText={errTxts.last_name}
								name="last_name"
								onChange={handleChange}
								value={form.last_name}
								label="Last Name"
								required
								className="w-2/3 my-12 width-first-name"
							/>
							<FormControl
								className="w-2/3 my-12 width-first-name"
								required
								error={!!errTxts.relation_id}
							>
								<InputLabel id="state-label">Select</InputLabel>
								<Select
									onChange={handleChange}
									value={form.relation_id}
									disabled={isEdit}
									name="relation_id"
									labelId="state-label"
									id="status"
									placeholder=""
								>
									{form.relation_with_child === 'Legal Guardian' && isEdit ? (
										<MenuItem value={form.relation_id}>Legal Guardian</MenuItem>
									) : (
										relations?.map((relation) => {
											return (
												<MenuItem key={relation.id} value={relation.id}>
													{relation.name}
												</MenuItem>
											);
										})
									)}
								</Select>
								{errTxts.relation_id && <FormHelperText>{errTxts.relation_id}</FormHelperText>}
							</FormControl>
							{form.relation_id !== 1 && form.relation_id !== 3 ? (
								<TextField
									name="relation_with_child"
									onChange={handleChange}
									value={form.relation_with_child}
									label="Relation With Child"
									className="w-2/3 my-12 width-first-name"
									error={!!errTxts.relation_with_child}
									required
									helperText={errTxts.relation_with_child}
								/>
							) : (
								<FormControl
									className="my-12 width-relation-with-child"
									error={!!errTxts.relation_with_child}
									required
								>
									<InputLabel id="state-label">Relation With Child</InputLabel>
									<Select
										onChange={handleChange}
										value={
											form.relation_with_child === 'Legal Guardian'
												? form.relation_with_child
												: form.relation_with_child?.toLowerCase()
										}
										name="relation_with_child"
										labelId="state-label"
										id="status"
										placeholder=""
										className="width-relation-with-child"
									>
										{form.relation_with_child === 'Legal Guardian' || form.relation_id === 3 ? (
											<MenuItem value="Legal Guardian">Legal Guardian</MenuItem>
										) : (
											[
												<MenuItem value="father">Father</MenuItem>,
												<MenuItem value="mother">Mother</MenuItem>,
											]
										)}
									</Select>
									{errTxts.relation_with_child && (
										<FormHelperText>{errTxts.relation_with_child}</FormHelperText>
									)}
								</FormControl>
							)}
							<TextField
								// disabled={isEdit}
								error={!!errTxts.email}
								helperText={errTxts.email}
								name="email"
								onChange={handleChange}
								value={form.email}
								label="Email Address"
								required
								className="w-2/3 my-12 width-first-name"
							/>
							<CustomPhoneInput
								required
								value={form.phone}
								onChange={(phone, country, e, formattedValue) => {
									setForm({ ...form, phone: formattedValue });
									setErrTxts({ ...errTxts, phone: '' });
								}}
								label={'Contact Number'}
								errTxt={errTxts.phone}
							/>
							<FormControl className="w-2/3 my-12" error={!!errTxts.can_pickup}>
								<div className="flex sm:flex-col gap-12">
									<div className="hd-pickup">Can Pickup *</div>
									<div className="flex gap-32">
										<div
											onClick={() => setForm({ ...form, can_pickup: 1 })}
											className="option-pickup cursor-pointer"
										>
											{' '}
											<span style={{ background: form.can_pickup === 1 ? '#4DA0EE' : 'white' }}>
												<i className="fas fa-check" />
											</span>{' '}
											Yes
										</div>
										<div
											onClick={() => setForm({ ...form, can_pickup: 0 })}
											className="option-pickup cursor-pointer"
										>
											{' '}
											<span style={{ background: form.can_pickup === 0 ? '#4DA0EE' : 'white' }}>
												<i className="fas fa-check" />
											</span>{' '}
											No
										</div>
									</div>
								</div>
								{errTxts.can_pickup && <FormHelperText>{errTxts.can_pickup}</FormHelperText>}
							</FormControl>

							<FormControl error={!!errTxts.emergency_contact} variant="standard" className="w-2/3 my-12">
								<div className="flex sm:flex-col gap-12">
									<div className="hd-pickup">Emergency Contact *</div>
									<div className="flex gap-32">
										<div
											onClick={() => setForm({ ...form, emergency_contact: true })}
											className="option-pickup cursor-pointer"
										>
											{' '}
											<span
												style={{
													background: form.emergency_contact === true ? '#4DA0EE' : 'white',
												}}
											>
												<i className="fas fa-check" />
											</span>{' '}
											Yes
										</div>
										<div
											onClick={() => setForm({ ...form, emergency_contact: false })}
											className="option-pickup cursor-pointer"
										>
											{' '}
											<span
												style={{
													background: form.emergency_contact === false ? '#4DA0EE' : 'white',
												}}
											>
												<i className="fas fa-check" />
											</span>{' '}
											No
										</div>
									</div>
								</div>
								{errTxts.emergency_contact && (
									<FormHelperText>{errTxts.emergency_contact}</FormHelperText>
								)}
							</FormControl>
							{/* 1 = parent, 3 = legal guardian */}
							{[1, 3].includes(form.relation_id) && !token && (
								<FormControl
									error={!!errTxts.is_primary_account}
									disabled={isEdit && contact.is_primary_account === 1}
									variant="standard"
									className="w-2/3 my-12"
								>
									<div className="flex sm:flex-col gap-12">
										<div
											className={`hd-pickup ${
												isEdit && contact.is_primary_account && 'disabled'
											}`}
										>
											Primary Account Holder *
										</div>
										<div className="flex gap-32">
											<div
												onClick={() =>
													!(isEdit && contact.is_primary_account) &&
													setForm({ ...form, is_primary_account: 1 })
												}
												className={`option-pickup ${
													isEdit && contact.is_primary_account && 'disabled'
												} cursor-pointer`}
											>
												{' '}
												<span
													style={{
														background:
															(isEdit && contact.is_primary_account && 'grey') ||
															(form.is_primary_account === 1 ? '#4DA0EE' : 'white'),
													}}
												>
													<i className="fas fa-check" />
												</span>{' '}
												Yes
											</div>
											<div
												onClick={() =>
													!(isEdit && contact.is_primary_account) &&
													setForm({ ...form, is_primary_account: 0 })
												}
												className={`option-pickup ${
													isEdit && contact.is_primary_account && 'disabled'
												} cursor-pointer`}
											>
												{' '}
												<span
													style={{
														background:
															(isEdit && contact.is_primary_account && 'white') ||
															(form.is_primary_account === 0 ? '#4DA0EE' : 'white'),
													}}
												>
													<i className="fas fa-check" />
												</span>{' '}
												No
											</div>
										</div>
									</div>
									{errTxts.is_primary_account && (
										<FormHelperText>{errTxts.is_primary_account}</FormHelperText>
									)}
								</FormControl>
							)}
							<FormControl
								disabled
								error={!!errTxts.is_app_access}
								variant="standard"
								className="w-2/3 my-12"
							>
								<div className="flex sm:flex-col gap-12">
									<div className="hd-pickup disabled">Granted App Access *</div>
									<div className="flex gap-32">
										<div className="option-pickup disabled cursor-pointer">
											{' '}
											<span
												style={{
													background:
														form.is_app_access_label === 'Granted App Access' ||
														form.is_app_access_label === 'Primary Account Holder'
															? 'grey'
															: 'white',
												}}
											>
												<i className="fas fa-check" />
											</span>{' '}
											Yes
										</div>
										<div className="option-pickup disabled cursor-pointer">
											{' '}
											<span
												style={{
													background:
														form.is_app_access_label !== 'Granted App Access' &&
														form.is_app_access_label !== 'Primary Account Holder'
															? 'grey'
															: 'white',
												}}
											>
												<i className="fas fa-check" />
											</span>{' '}
											No
										</div>
									</div>
								</div>
								{errTxts.is_app_access && <FormHelperText>{errTxts.is_app_access}</FormHelperText>}
							</FormControl>
						</div>
					</div>
					{isAdding ? (
						<div className="flex justify-center mt-36">
							<CircularProgress className="mx-auto" />
						</div>
					) : (
						<div className="flex justify-center w-max mt-36" style={{ gap: '20px' }}>
							<CustomButton variant="secondary" onClick={goBack}>
								Cancel
							</CustomButton>
							<CustomButton
								variant="primary"
								onClick={() => {
									handleSubmit();
								}}
							>
								{isEdit ? 'Update' : 'Add'}
							</CustomButton>
						</div>
					)}
				</div>
			</div>
		</FuseScrollbars>
	);
}

export default AddEditContact;

import axios from 'axios';

const createContact = (data) => {
	return axios.post('api/v2/admin/students/contact', data);
};

const updateContact = (id, student_id, data) => {
	return axios.put(`api/v2/admin/students/contact/${id}?student_id=${student_id}`, data);
};

export { createContact, updateContact };

import React from 'react';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@material-ui/core';
import history from '@/@history';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';

function MedicalView({ student, isLoading, setCurrentState, isAdmin, token }) {
	const goToEditMedications = () => {
		if (isAdmin) {
			history.push({ pathname: '/students-editmedications', state: { student, tab: 'medication' } });
		} else {
			history.push({ pathname: '/medications', state: { student, token, tab: 'medication' } });
		}
	};

	const handleNext = () => {
		setCurrentState('room-attachments');
	};
	return (
		<>
			<div className="flex items-center flex-nowrap justify-between mx-auto mb-10">
				<span className="md:text-lg lg:text-xl self-end font-extrabold">Medical</span>
				<div className="personal-button flex justify-between">
					<span className="mx-4">
						<CustomButton
							variant="secondary"
							height="46"
							width="100px"
							fontSize="15px"
							onClick={goToEditMedications}
							disabled={isLoading}
						>
							<FontAwesomeIcon icon={faEdit} />
							<span> Edit </span>
						</CustomButton>
					</span>
				</div>
			</div>
			<div className="per-detail bg-white mx-auto">
				{isLoading ? (
					<div className="flex align-center justify-center">
						<CircularProgress size={35} />
					</div>
				) : (
					<>
						<div className="grid grid-cols-2 md:grid-cols-3 row-gap-32">
							<div className="flex flex-col items-start medical-info-1 col-span-2 sm:col-span-1">
								<div>Allergies</div>
								<div className="md:text-lg lg:text-lg font-bold">
									{student?.allergies ||
										`${student?.first_name} ${student?.last_name} has no allergies`}
								</div>
							</div>
							<div className="flex flex-col items-start medical-info-2">
								<div>Doctor</div>
								<div className="md:text-lg lg:text-lg font-bold">
									{student?.doctor?.name ||
										`${student?.first_name} ${student?.last_name} has no Doctor on record`}
								</div>
							</div>
							<div className="flex flex-col items-start medical-info-1">
								<div>Medical Condition</div>
								<div className="md:text-lg lg:text-lg font-bold">
									{student?.is_medical_condition === 1
										? 'Yes'
										: `${student?.first_name} ${student?.last_name} has no medical condition on record`}
								</div>
							</div>
						</div>
						<div className="flex">
							<div className="flex flex-col items-start mt-32">
								<div>Description</div>
								<div className="md:text-lg lg:text-lg font-bold">
									{student?.is_medical_condition ? student.description || 'N/A' : 'N/A'}
								</div>
							</div>
						</div>
						<hr className="mt-32" style={{ color: 'lightgrey' }} />
						<div style={{ overflowX: 'auto' }}>
							<table className="mt-20 w-full">
								<thead className="bg-blue-100">
									<th
										style={{ minWidth: 50 }}
										className="size-head font-bold py-16 pl-16 med-table-headers text-left"
									>
										Medication
									</th>
									<th
										style={{ minWidth: 50 }}
										className="size-head font-bold py-16 med-table-headers text-left"
									>
										Frequency
									</th>
									<th
										style={{ minWidth: 50 }}
										className="size-head font-bold py-16 med-table-headers text-left"
									>
										Start Date
									</th>
									<th
										style={{ minWidth: 50 }}
										className="size-head font-bold py-16 med-table-headers text-left"
									>
										End Date
									</th>
								</thead>
								<tbody>
									{student?.is_medical_condition === 1 ? (
										student?.medications.length ? (
											student?.medications.map((medication) => {
												return <MedicationItem medication={medication} key={medication.id} />;
											})
										) : (
											<tr>
												<td colSpan={4}>
													<div className="flex justify-center mt-20 font-bold text-lg text-size-head">
														No Medication
													</div>
												</td>
											</tr>
										)
									) : (

										<tr>
											<td colSpan={4}>
												<div className="flex justify-center mt-20 font-bold text-lg text-size-head">
													No Medical Condition
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</>
				)}
			</div>
			<div className="w-full flex justify-between mt-20 mb-80 pb-80 lg:mb-64 lg:pb-64">
				<CustomButton
					className=""
					variant="primary"
					height="46"
					width="120px"
					fontSize="15px"
					onClick={() => {
						setCurrentState('personal');
					}}
				>
					<div className="flex items-center justify-center">
						<ArrowBackIos className="" style={{ fontSize: '1.6rem' }} />
						Previous
					</div>
				</CustomButton>
				<CustomButton
					className=""
					variant="primary"
					height="46"
					width="100px"
					fontSize="15px"
					onClick={handleNext}
					disabled={isLoading}
				>
					<div className="flex items-center justify-center ml-6">
						Next
						<ArrowForwardIos className="ml-6" style={{ fontSize: '1.6rem' }} />
					</div>
				</CustomButton>
			</div>
		</>
	);
}

const MedicationItem = ({ medication }) => {
	return (
		<>
			<tr className="border-b">
				<td className="py-16 pl-16">{medication.name}</td>
				<td className="py-16">{medication.frequency}</td>
				<td className="py-16">{medication.start_date}</td>
				<td className="py-16">{medication.end_date}</td>
			</tr>
			{/* <hr className="" style={{ color: 'lightgrey' }} /> */}
		</>
	);
};

export default MedicalView;

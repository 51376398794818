import React from 'react';
import authRoles from '../../../auth/authRoles';
import Rooms from './Rooms';
import Addschedules from './addschedules';
import addschedules from './addschedules';
import Updateschedulee from './updateschedule';
import roomschedule from './roomschedule';
import Roomsetting from './Roomsetting';
import Room from './Room';
import AddRoom from './AddRoom';
import FeedsTypeDetail from './FeedsTypeDetail';
import AddLocation from './AddLocation';

const StudentInformationConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/rooms',
			// component: Rooms,
			component: React.lazy(() => import('./Rooms')),
		},
		{
			path: '/rooms-addschedules/:id',
			// component: addschedules,
			component: React.lazy(() => import('./addschedules')),
		},
		{
			path: '/rooms-updateschedule/:id',
			// component: Updateschedulee,
			component: React.lazy(() => import('./updateschedule')),
		},
		{
			path: '/rooms-roomschedule/:id',
			// component: roomschedule,
			component: React.lazy(() => import('./roomschedule')),
		},
		{
			path: '/rooms-roomsetting',
			// component: Roomsetting,
			component: React.lazy(() => import('./Roomsetting')),
		},
		{
			path: '/rooms-room/:id',
			// component: Room,
			component: React.lazy(() => import('./Room')),
		},
		{
			path: '/rooms-addroom',
			// component: AddRoom,
			component: React.lazy(() => import('./AddRoom')),
		},
		{
			path: '/rooms-FeedsType/:id',
			// component: FeedsTypeDetail,
			component: React.lazy(() => import('./FeedsTypeDetail')),
		},
		{
			path: '/location-add',
			// component: AddLocation,
			component: React.lazy(() => import('./AddLocation')),
		},
	],
	auth: authRoles.subadmin,
};

export default StudentInformationConfig;

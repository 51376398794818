import React, { useState, useEffect } from 'react';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Box, IconButton, FormControl, MenuItem, Select, CircularProgress } from '@material-ui/core';
import { generateAgeString, calculateDueOverDue } from '@/utils/utils';
import { updateImmunizationList, getImmunizations } from '@/app/services/immunizations/Immunization';
import * as Actions from '@/app/store/actions/';
import dayjs from 'dayjs';
import CustomDatePicker from '@/app/customComponents/CustomDatePicker/CustomDatePicker';
import ImmunizationListDialog from './ImmunizationFilterDialog';
import Notes from './Notes';
import { useDispatch } from 'react-redux';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import Axios from 'axios';
import history from '@/@history';
import { isSafari } from '@/utils/utils';

function ImmunizationView({ isLoading, student, age, refresh, setRefresh, setCurrentState, isAdmin, token }) {
	const dispatch = useDispatch();
	const [isEditingDates, setIsEditingDates] = useState(false);
	const [modifiedImmunizations, setModifiedImmunizations] = useState([]);
	const [due, setDue] = useState([]);
	const [overDue, setOverDue] = useState([]);
	const [immunizations, setImmunizations] = useState([]);
	const [isUpdating, setIsUpdating] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	useEffect(() => {
		if (student && age && !isLoading) {
			console.log('student', student);
			const [overDuedoses, duedoses] = calculateDueOverDue(student, age);
			setDue(duedoses);
			setOverDue(overDuedoses);
		}
	}, [isLoading, student, age]);

	const handleExempt = (immu, i) => {
		const temp = [...modifiedImmunizations];
		const index = temp.findIndex((item) => item.id === immu.id);
		temp[index].is_exempted = temp[index].is_exempted ? 0 : 1;
		setModifiedImmunizations(temp);
	};

	const handleImmunizationSave = () => {
		for (const immu of modifiedImmunizations) {
			if (immu.is_exempted && !immu.exempt_reason) {
				dispatch(
					Actions.showMessage({
						message: `Please select exempt reason for ${immu.immunization?.name?.split('-')[0]}`,
						variant: 'error',
					})
				);
				return;
			}
		}
		setIsUpdating(true);
		updateImmunizationList(student.id, modifiedImmunizations)
			.then((res) => {
				setIsUpdating(false);
				setRefresh(refresh + 1);
				setIsEditingDates(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleImmunizationFilter = (list) => {
		setIsEditingDates(false);
		dispatch(
			Actions.openDialog({
				children: (
					<ImmunizationListDialog
						refresh={refresh}
						setRefresh={setRefresh}
						rows={list}
						enabled={student.immunizations}
						studentId={student.id}
					/>
				),
			})
		);
	};
	useEffect(() => {
		getImmunizations().then((res) => setImmunizations(res.data));
	}, []);
	useEffect(() => {
		if (student?.immunizations) {
			setModifiedImmunizations(student.immunizations.filter((item) => item.is_enabled));
		}
	}, [student]);

	const handleFormSubmit = () => {
		if (isEditingDates) {
			dispatch(
				Actions.showMessage({
					message: 'Please save the immunization dates before proceeding',
					variant: 'warning',
					anchorOrigin: {
						vertical: isSafari() ? 'bottom' : 'top',
						horizontal: 'center',
					},
				})
			);
			return;
		}
		if (isAdmin) {
			setSubmitLoading(true);
			Axios.post('/api/v1/school/parent/token/submit', { student_id: student.id })
				.then((res) => {
					dispatch(
						Actions.showMessage({
							message: 'Form submitted successfully',
							variant: 'success',
						})
					);
					history.push('/students/student' + student.id);
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: 'Something went wrong',
							variant: 'error',
						})
					);
					setSubmitLoading(false);
				});
		} else {
			setSubmitLoading(true);
			Axios.post('/api/v1/parent/token/submit', { token, student_id: student.id })
				.then((res) => {
					dispatch(
						Actions.showMessage({
							message: 'Form submitted successfully',
							variant: 'success',
						})
					);
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: 'Something went wrong',
							variant: 'error',
						})
					);
				})
				.finally(() => {
					setSubmitLoading(false);
				});
		}
	};

	return (
		<>
			<div className="P-m text-xxs sm:text-xs md:text-base">
				<div className="immunization  items-center flex-nowrap  mx-auto">
					<h4 className="md:text-lg lg:text-xl self-end font-extrabold">Immunizations</h4>
					<p>
						Immunizations tracks past student immunization dates and calculates overdue and due vaccines
						based on CDC recommendations.
					</p>
					<div className="flex justify-between items-start mt-10 main-box-width" style={{ gap: 10 }}>
						<div className="box-imm flex items-center flex-nowrap justify-between main-box-div">
							<h3 className="space-btm md:text-lg lg:text-xl self-end font-extrabold mt-10">
								CDC Recommendations
							</h3>
							<div className="recomd-imm p-32 sm:p-64 grid grid-cols-2 gap-10">
								<div className="font-bold mr-10 mb-4">
									<span className="bg-red rounded-full py-4 px-8 sm:px-10 md:px-12 text-white font-extrabold mr-4">
										!
									</span>
									{overDue?.filter((item) => item?.count !== 0)?.length} Overdue
								</div>
								<div>
									<p className="">
										{overDue?.map((item) => {
											if (item?.count !== 0) {
												return (
													<>
														{item?.name}: {item?.count}
														{item?.count === 1 ? ' dose ' : ' doses '} over due
														<br />
													</>
												);
											}
											return <></>;
										})}
										{overDue?.filter((item) => item?.count !== 0)?.length === 0
											? 'No over due doses'
											: ''}
									</p>
								</div>
								<div className="font-bold mr-10">
									<span className="bg-green rounded-full py-4 px-4 sm:px-6 text-white font-extrabold mr-4">
										<i className="fas fa-check" />
									</span>
									{due?.length} Due
								</div>
								<div>
									<p className="">
										{due?.map((item) => (
											<>
												{item}
												<br />
											</>
										))}
										{due?.length === 0 ? 'No dose due' : ''}
									</p>
								</div>
							</div>
						</div>
						<div className="box-imm flex items-center flex-nowrap justify-between main-box-div">
							<Notes student={student} setRefresh={setRefresh} isLoading={isLoading} />
						</div>
					</div>
				</div>
			</div>
			<div className="P-m Immunizations-inner text-xxs sm:text-xs md:text-base">
				<Box className=" mx-auto pb-10">
					<div className="w-full flex justify-between  mx-auto">
						<h4 className="md:text-lg lg:text-xl self-end font-extrabold">Immunizations</h4>
						<div className="flex">
							<IconButton
								className="mr-4"
								size="small"
								disabled={isLoading}
								onClick={() => handleImmunizationFilter(immunizations)}
							>
								<img
									src="assets/images/setting-icon.png"
									alt="filter"
									width="35px"
									className="fiterimg"
								/>
							</IconButton>
							{isEditingDates ? (
								(isUpdating && <CircularProgress size={35} />) || (
									<CustomButton variant="primary" onClick={handleImmunizationSave}>
										Done
									</CustomButton>
								)
							) : (
								<CustomButton
									variant="secondary"
									fontSize="14px"
									disabled={isLoading}
									onClick={() => setIsEditingDates(true)}
								>
									<div className="flex justify-center items-center">
										<FontAwesomeIcon icon={faEdit} />
										<span className="spacebtn">Edit</span>
									</div>
								</CustomButton>
							)}
						</div>
					</div>
				</Box>
				<Box className="bg-white p-12 mx-auto mb-44 overflow-auto ">
					<table className="w-full">
						<thead className="hidden sm:table-header-group">
							<tr style={{ borderBottom: '1px solid #DBDBDB' }}>
								{!isEditingDates ? <th style={{ style: '25%' }} /> : ''}
								<th
									style={{
										width: '15%',
										height: '55px',
										color: '#002E6C',
										fontWeight: '700',
									}}
								>
									Dose 1
								</th>
								<th
									style={{
										width: '15%',
										height: '55px',
										color: '#002E6C',
										fontWeight: '700',
									}}
								>
									Dose 2
								</th>
								<th
									style={{
										width: '15%',
										height: '55px',
										color: '#002E6C',
										fontWeight: '700',
									}}
								>
									Dose 3
								</th>
								<th
									style={{
										width: '15%',
										height: '55px',
										color: '#002E6C',
										fontWeight: '700',
									}}
								>
									Dose 4
								</th>
								<th
									style={{
										width: '15%',
										height: '55px',
										color: '#002E6C',
										fontWeight: '700',
									}}
								>
									Dose 5
								</th>
							</tr>
						</thead>
						<tbody className="border-b">
							<tr style={{ columnSpan: 6 }} className="age-height	">
								<td style={{ color: '#06071D', fontWeight: '800' }} colSpan={6}>
									Student age: {generateAgeString(student?.date_of_birth)}
								</td>
							</tr>
							{student?.immunizations
								?.filter((imm) => imm.is_enabled)
								.map((immu, i) => {
									return (
										<React.Fragment key={immu.id}>
											<tr className="bg-blue-200 tr-blue">
												<td
													className="p-8"
													colSpan="6"
													style={{ color: '#06071D', fontWeight: '800' }}
												>
													<div className="flex items-center justify-between">
														<div>{immu.immunization.name}</div>
														{isEditingDates && (
															<div
																style={{ gap: 20 }}
																className="flex items-center align-center"
															>
																{immu.is_exempted ? (
																	<FormControl className="font-normal">
																		<Select
																			name="reason"
																			id="reason"
																			placeholder="Exempt Reason"
																			defaultValue="0"
																			value={immu.exempt_reason || '0'}
																			onClick={(e) => {
																				const temp = [...modifiedImmunizations];
																				const index = temp.findIndex(
																					(item) => item.id === immu.id
																				);
																				temp[index].exempt_reason =
																					e.target.value;
																				setModifiedImmunizations(temp);
																			}}
																			className="exempt-reason-select"
																		>
																			<MenuItem value="0" disabled>
																				Exempt Reason
																			</MenuItem>
																			<MenuItem value="medical">Medical</MenuItem>
																			<MenuItem value="personal-believe">
																				Personal believe
																			</MenuItem>
																			<MenuItem value="immue">Immune</MenuItem>
																			<MenuItem value="unknown">Unknown</MenuItem>
																			<MenuItem value="other">Other</MenuItem>
																		</Select>
																	</FormControl>
																) : (
																	''
																)}
																<div
																	className="flex items-center cursor-pointer"
																	style={{ gap: 10 }}
																	onClick={() => handleExempt(immu, i)}
																>
																	<div
																		className="tick-wrapper-edit-dates"
																		style={{
																			backgroundColor: immu.is_exempted
																				? '#4DA0EE'
																				: 'white',
																		}}
																	>
																		<i className="fas fa-check" />
																	</div>
																	<div className="font-bold">Exempt</div>
																</div>
															</div>
														)}
													</div>
												</td>
											</tr>
											{isEditingDates && !immu.is_exempted ? (
												<tr className="flex flex-col sm:table-row">
													{modifiedImmunizations[
														modifiedImmunizations?.findIndex((item) => item.id === immu.id)
													]?.meta.map((dose, ind) => {
														return dose.skip ? (
															<td>
																<SkippedDate
																	setModifiedImmunizations={setModifiedImmunizations}
																	modifiedImmunizations={modifiedImmunizations}
																	doseIndex={ind}
																	immuIndex={i}
																/>
															</td>
														) : (
															<td>
																<EditImmunizationDateDiv
																	setModifiedImmunizations={setModifiedImmunizations}
																	modifiedImmunizations={modifiedImmunizations}
																	doseIndex={ind}
																	immuIndex={i}
																	studentAge={student?.date_of_birth}
																/>
															</td>
														);
													})}
													{[1, 2, 3, 4, 5].slice(0, 5 - immu.meta.length).map((num) => {
														return (
															<td key={num}>
																<EditImmunizationDateDiv disabledDiv />
															</td>
														);
													})}
												</tr>
											) : (
												(isEditingDates && immu.is_exempted && (
													<div className="py-28 px-6">Uncheck exempt to add dates.</div>
												)) || (
													<>
														<tr className="records">
															<td
																className="p-8 "
																style={{ color: '#06071D', fontWeight: '800' }}
															>
																Student Records
															</td>
															{immu.is_exempted ? (
																<>
																	<td
																		className="p-8 text-center"
																		style={{
																			backgroundColor: '#FFF8C7',
																			fontWeight: '600',
																		}}
																	>
																		Exempt
																	</td>
																	<td
																		className="p-8"
																		colSpan={4}
																		style={{ backgroundColor: '#FFF8C7' }}
																	/>
																</>
															) : (
																immu.meta.map((dose) => {
																	if (dose.is_recurring === '1') {
																		return (
																			<td
																				style={{
																					fontWeight: '600',
																				}}
																				className="p-8 text-center"
																			/>
																		);
																	}
																	if (dose.skip) {
																		return (
																			<td
																				style={{
																					fontWeight: '600',
																				}}
																				className="p-8 text-center"
																			>
																				Skipped
																			</td>
																		);
																	}
																	return (
																		<td
																			className="p-8 text-center"
																			style={{
																				backgroundColor: dose.date
																					? 'white'
																					: age?.allMonths <=
																					  dose.max_duration
																					? '#DFFFED'
																					: '#FFEBEA',
																				fontWeight: '600',
																			}}
																		>
																			{dose.date
																				? dayjs(dose.date).format('MM/DD/YYYY')
																				: age?.allMonths <= dose.max_duration
																				? 'Due'
																				: 'Overdue'}
																		</td>
																	);
																})
															)}
														</tr>

														<tr className="records-second">
															<td
																className="p-8"
																style={{ color: '#06071D', fontWeight: '800' }}
															>
																CDC Recommendation
															</td>
															{immu.meta.map((dose) => {
																return (
																	<td
																		className="p-8 text-center"
																		style={{
																			color: '#06071D',
																			fontWeight: '600',
																		}}
																	>
																		{dose.duration_text}
																	</td>
																);
															})}
														</tr>
													</>
												)
											)}
										</React.Fragment>
									);
								})}
						</tbody>
					</table>
				</Box>
				<div className="w-full flex justify-between gap-6 mt-20 mb-80 pb-80 lg:mb-64 lg:pb-64">
					<CustomButton
						className=""
						variant="primary"
						height="46"
						width="120px"
						fontSize="15px"
						onClick={() => setCurrentState('contacts')}
					>
						<div className="flex items-center justify-center">
							<ArrowBackIos className="" style={{ fontSize: '1.6rem' }} />
							Previous
						</div>
					</CustomButton>
					{submitLoading ? (
						<CircularProgress size={35} />
					) : (
						<CustomButton height="46" width="120px" fontSize="15px" onClick={handleFormSubmit}>
							Submit
						</CustomButton>
					)}
				</div>
			</div>
		</>
	);
}

export default ImmunizationView;

const EditImmunizationDateDiv = ({
	modifiedImmunizations,
	setModifiedImmunizations,
	immuIndex,
	doseIndex,
	disabledDiv,
	studentAge,
}) => {
	const [disabled, setDisabled] = useState(!!doseIndex);

	useEffect(() => {
		let t = false;
		for (let i = 0; i < doseIndex; i += 1) {
			if (
				modifiedImmunizations[immuIndex]?.meta[i].date === null &&
				!modifiedImmunizations[immuIndex]?.meta[i].skip
			) {
				t = true;
				break;
			}
		}
		setDisabled(t);
	}, [modifiedImmunizations]);

	const handleSkip = () => {
		if (disabledDiv) {
			return;
		}
		if (doseIndex !== 0) {
			if (
				modifiedImmunizations[immuIndex].meta[doseIndex - 1].date === null &&
				!modifiedImmunizations[immuIndex].meta[doseIndex - 1].skip
			) {
				return;
			}
		}
		const temp = [...modifiedImmunizations];
		temp[immuIndex].meta[doseIndex].skip = 1;
		setModifiedImmunizations(temp);
	};
	const handleDateChange = (date) => {
		const temp = [...modifiedImmunizations];
		temp[immuIndex].meta[doseIndex].date = date ? dayjs(date).format('YYYY-MM-DD') : '';
		setModifiedImmunizations(temp);
	};
	return (
		<div
			className={`flex flex-row sm:flex-col items-center sm:items-start align-left p-4 sm:p-16 sm:pr-20 text-xxs sm:text-xs md:text-base font-100 ${
				disabledDiv ? 'hidden sm:flex' : ''
			}`}
			style={{ gap: 20 }}
		>
			<div className="flex-grow sm:hidden">Dose: {doseIndex + 1}</div>
			<CustomDatePicker
				value={disabledDiv ? '' : modifiedImmunizations[immuIndex].meta[doseIndex].date}
				setValue={handleDateChange}
				label="Date"
				width="100%"
				disabled={disabled || disabledDiv}
				minDate={
					(!disabledDiv &&
						((doseIndex === 0 && studentAge) ||
							(modifiedImmunizations[immuIndex].meta[doseIndex - 1].skip && studentAge) ||
							modifiedImmunizations[immuIndex].meta[doseIndex - 1].date)) ||
					0
				}
				disableFuture
			/>
			<div className="flex items-center cursor-pointer gap-2 sm:gap-8 md:gap-16" onClick={handleSkip}>
				<div className="tick-wrapper-edit-dates">
					<i className="fas fa-check" />
				</div>
				<div>Skip</div>
			</div>
		</div>
	);
};

const SkippedDate = ({ modifiedImmunizations, setModifiedImmunizations, immuIndex, doseIndex }) => {
	const handleNotSkip = () => {
		const temp = [...modifiedImmunizations];
		temp[immuIndex].meta[doseIndex].skip = 0;
		setModifiedImmunizations(temp);
	};

	return (
		<div className="flex items-center align-left py-6 sm:py-20 text-xxs sm:text-xs md:text-base">
			<div className="flex items-center cursor-pointer" onClick={handleNotSkip} style={{ gap: 10 }}>
				<div className="tick-wrapper-edit-dates" style={{ background: '#4DA0EE' }}>
					<i className="fas fa-check" />
				</div>
				<div>Skipped</div>
			</div>
		</div>
	);
};

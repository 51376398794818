import React from 'react';
import authRoles from '../../../auth/authRoles';
import Reports from './Reports';
import Checkin from './Checkin';
import Attendance from './Attendance';
import Activity from './Activity';
import Staff from './Staff';
import Age from './Age';
import EnrolledStudent from './EnrolledStudent';
import RoomRatio from './RoomRatio';
import Allergy from './Allergy';
import Medication from './Medication';
import Contact from './Contact';
import SubscriptionReport from './SubscriptionReport';
import SchoolRoyalties from './SchoolRoyalties';
import Immunization from './ImmunizationReport';
import DueOverDueReport from './DueOverDueReport';
import Meal from './Meal';
import DownloadReports from './DownloadReports';
import RoomCheck from './RoomCheck';

const ReportsConfig = {
	reports: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/reports',
			// component: Reports
			component: React.lazy(() => import('./Reports')),
		},
		{
			path: '/reports-checkin',
			// component: Checkin
			component: React.lazy(() => import('./Checkin')),
		},
		{
			path: '/reports-attendance',
			// component: Attendance
			component: React.lazy(() => import('./Attendance')),
		},
		{
			path: '/reports-activity',
			// component: Activity
			component: React.lazy(() => import('./Activity')),
		},
		{
			path: '/reports-staff',
			// component: Staff
			component: React.lazy(() => import('./Staff')),
		},
		{
			path: '/reports-age',
			// component: Age
			component: React.lazy(() => import('./Age')),
		},
		{
			path: '/reports-enrolledStudent',
			// component: EnrolledStudent
			component: React.lazy(() => import('./EnrolledStudent')),
		},
		{
			path: '/RoomCheck',
			// component: RoomCheck
			component: React.lazy(() => import('./RoomCheck')),
		},
		{
			path: '/reports-roomRatio',
			// component: RoomRatio
			component: React.lazy(() => import('./RoomRatio')),
		},
		{
			path: '/reports-allergy',
			// component: Allergy
			component: React.lazy(() => import('./Allergy')),
		},
		{
			path: '/reports-medication',
			// component: Medication
			component: React.lazy(() => import('./Medication')),
		},
		{
			path: '/reports-contact',
			// component: Contact
			component: React.lazy(() => import('./Contact')),
		},
		{
			path: '/reports-subscription',
			// component: SubscriptionReport
			component: React.lazy(() => import('./SubscriptionReport')),
		},
		{
			path: '/reports-royalties',
			// component: SchoolRoyalties
			component: React.lazy(() => import('./SchoolRoyalties')),
		},
		{
			path: '/reports-meal',
			// component: Meal
			component: React.lazy(() => import('./Meal')),
		},
		{
			path: '/reports-immunization',
			// component: Immunization
			component: React.lazy(() => import('./ImmunizationReport')),
		},
		{
			path: '/reports-due-overdue',
			// component: DueOverDueReport
			component: React.lazy(() => import('./DueOverDueReport')),
		},
		{
			path: '/reports-download',
			// component: DownloadReports
			component: React.lazy(() => import('./DownloadReports')),
		},
	],
	auth: authRoles.subadmin,
};

export default ReportsConfig;

import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import NavbarMobileToggleButton from '@/app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from '@/app/fuse-layouts/shared-components/UserMenu';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SchoolAdminTopNav from './schoolAdminTopNav/SchoolAdminTopNav';
import Topbar from './schoolAdminTopNav/Topbar';
import SuperAdminTopNav from './superAdminTopNav/superAdminTopNav';
import history from '@/@history';
import { stopBeamsClient } from '@/utils/utils';

const useStyles = makeStyles((theme) => ({
	separator: {
		marginTop: 8,
		width: 1,
		height: 50,
		backgroundColor: theme.palette.divider,
	},
}));

function ToolbarLayout1(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);

	const classes = useStyles(props);
	const [unreadCount, setUnreadCount] = useState(0);
	const user = useSelector(({ auth }) => auth.user);
	let beamsClient;
	let id;
	if (user.role[0] === 'super_admin' || user.role[0] === 'school_admin' || user.role[0] === 'sub_admin') {
		id = user.data.id;
	} else {
		id = user?.school?.admins ? user.school.admins[0].id : user?.school?.pivot?.user_id;
	}

	const startBeamsClient = () => {
		window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
			beamsClient = new PusherPushNotifications.Client({
				instanceId: import.meta.env.VITE_BEAMS_INSTANCE_ID,
				serviceWorkerRegistration,
			});
			beamsClient
				.getRegistrationState()
				.then((state) => {
					const states = PusherPushNotifications.RegistrationState;
					switch (state) {
						case states.PERMISSION_DENIED: {
							// Notifications are blocked
							// Show message saying user should unblock notifications in their browser
							break;
						}
						case states.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS: {
							beamsClient
								.addDeviceInterest(`App.Models.User.${id}`)
								.then(() => beamsClient.getDeviceInterests())
								.then((interests) => console.log('Current interests:', interests))
								.catch(console.error);
							break;
						}
						case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS:
						case states.PERMISSION_PROMPT_REQUIRED: {
							beamsClient
								.start()
								.then((client) => client.getDeviceId())
								.then((deviceId) =>
									console.log('Successfully registered with Beams. Device ID:', deviceId)
								)
								.then(() => beamsClient.addDeviceInterest(`App.Models.User.${id}`))
								.then(() => beamsClient.getDeviceInterests())
								.then((interests) => console.log('Current interests:', interests))
								.catch(console.error);
							break;
						}
						default: {
							throw new Error(`Unexpected Pusher SDK state: ${state}`);
						}
					}
				})
				.catch((e) => console.error('Could not get registration state', e));
		});
	};
	useEffect(() => {
		if (user.role.length > 0) {
			startBeamsClient();
		}

		return () => {
			stopBeamsClient(beamsClient);
		};
	}, [user]);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className="flex fixed z-10"
				color="default"
				style={{ backgroundColor: '#fff', height: 81 }}
			>
				<Toolbar className="p-0">
					{config.navbar.display && config.navbar.position === 'left' && (
						<Hidden lgUp>
							<NavbarMobileToggleButton className="w-64 h-64 p-0" />
							<div className={classes.separator} />
						</Hidden>
					)}

					<div className="flex flex-2 ml-25 items-center" style={{ marginLeft: '35px' }}>
						<img
							className={`${user?.role?.length && 'cursor-pointer'}`}
							src="assets/images/logos/logo.png"
							alt=""
							width={138}
							style={{ height: '100%' }}
							onClick={() => {
								console.log(user?.role?.length);
								if (user.role?.length) {
									if (user.role[0] === 'super_admin') {
										history.push('/company');
									} else {
										history.push('/home');
									}
								}
							}}
						/>
						<div className={classes.separator} style={{ marginLeft: '64px' }} />
					</div>
					<div className="flex flex-1">
						{(user.role[0] === 'school_admin' ||
							user.role[0] === 'super_school_admin' ||
							user.role[0] === 'sub_admin') && (
							<Topbar key={user} setUnreadCount={setUnreadCount} unreadCount={unreadCount} />
						)}
					</div>
					<div className="flex justify-between items-center">
						{user.role[0] === 'super_admin' ? (
							<SuperAdminTopNav />
						) : user.role.length ? (
							<SchoolAdminTopNav setUnreadCount={setUnreadCount} unreadCount={unreadCount} />
						) : (
							''
						)}
						{user.role[0] !== 'super_admin' && <div className={classes.separator} />}
						{user.role.length ? <UserMenu /> : ''}
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default ToolbarLayout1;
